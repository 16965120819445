import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { SectionContainer } from 'components/layout/SectionContainer';
import { Heading } from 'components/typography/Heading';
import { ActionBtn } from 'components/buttons/ActionBtn';
import { ElevationPower } from 'components/elements/ElevationPower';
import { Subheading } from 'components/typography/Subheading';
import { BinBtn } from 'components/buttons/BinBtn';
import { Configurator } from 'components/features/Configurator';

import { FormError } from 'components/form/FormError';

import { RootState } from 'types/store';

import { Warning } from 'assets/images';

import { setSystems } from 'store/slices/measurements/measurements.slice';
import { setFormSubmitted } from 'store/slices/results/results.slice';
import { clearGrid, setMaxPower, setWarningsAndValidSystems } from 'store/slices/configurator/configurator.slice';

import { hasPositionChanged } from 'components/features/ElevationsBreakdown/utils';
import { PATH_ELEV_CONFIGURE, PATH_ELEV_RESULTS } from 'constants/paths';

import './Configuration.styles.scss';

type Props = {};

const Configuration: FC<Props> = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const local_elevation_id = params?.local_elevation_id || '1';
  const navigate = useNavigate();
  const measurements = useSelector((state: RootState) => state.measurements.measurements);
  const grid = useSelector((state: RootState) => state.configurator.grid);

  const elevationIndex = Number(local_elevation_id) - 1;

  const elevationSelected = measurements?.elevations?.[elevationIndex];
  const elevationName = elevationSelected?.elevation_name || `Elevation ${elevationIndex}`;

  const previousGrid = useRef(grid);

  const validSystems = useSelector((state: RootState) => state.configurator.validSystems);

  const { max_columns = 0, max_rows = 0, limit_exceeded } = elevationSelected || {};

  useEffect(() => {
    // no elevation ?
    if (!elevationSelected) {
      // do somth
      navigate('/');
    }
  }, [elevationSelected, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [local_elevation_id]);

  const handleBack = useCallback(() => {
    navigate(-1);
    dispatch(setSystems({ index: elevationIndex, grid, validSystems }));
  }, [navigate, elevationIndex, dispatch, grid, validSystems]);

  const handleClear = useCallback(() => {
    dispatch(clearGrid());
  }, [dispatch]);

  const onMaxPossibleClick = useCallback(() => {
    dispatch(setMaxPower());
    dispatch(setWarningsAndValidSystems());
  }, [dispatch]);

  const isGridValid = useCallback(() => {
    let errCount: number = 0;
    grid.forEach((row) => {
      row.forEach((cell) => (cell.warning ? errCount++ : ''));
    });
    return errCount === 0 && !!validSystems?.length;
  }, [grid, validSystems?.length]);

  const handleConfirm = useCallback(async () => {
    const isValidGrid = isGridValid();
    if (isValidGrid) {
      dispatch(setSystems({ index: elevationIndex, grid, validSystems }));

      const hasChanged = hasPositionChanged(grid, previousGrid.current || []);

      if (hasChanged) {
        dispatch(setFormSubmitted(false));
      }
      if (measurements?.elevations?.length === Number(local_elevation_id)) {
        navigate(`${PATH_ELEV_RESULTS}`);
      } else {
        navigate(`${PATH_ELEV_CONFIGURE}/${Number(local_elevation_id) + 1}`);
      }
    }
  }, [
    dispatch,
    elevationIndex,
    grid,
    isGridValid,
    local_elevation_id,
    measurements?.elevations?.length,
    navigate,
    validSystems,
  ]);

  const { current_power, has_warnings } = useMemo(() => {
    let has_warnings = false;
    const selectedCells = grid
      .map((row) =>
        row.filter((cell) => {
          if (cell.warning) {
            has_warnings = true;
          }
          return cell.selected;
        })
      )
      .flat();
    const current_power = selectedCells?.length * (measurements?.panel_power || 0);
    return { current_power, has_warnings };
  }, [grid, measurements?.panel_power]);

  return (
    <div data-testid="Configuration" className="Configuration flex flex-col flex-1">
      <SectionContainer customClassNames="lg:p-0 relative">
        <div className="text-center w-full mb-6 mt-4 md:mt-0">
          <Subheading text={elevationName} color="black" bold />
        </div>
        <div className="flex flex-col items-center px-4 md:px-0 gap-4 text-center md:text-left">
          <Heading text="Configure your solar system" />
          <div className="flex flex-col gap-[4px] items-center">
            <Subheading text="Click on the grid to add your solar panels, to remove a panel simply click it again" />
            <Subheading text="You can also click and drag to add and remove in larger sections" />
          </div>
          {limit_exceeded && (
            <FormError message={'Please contact us to discuss in detail the system due to the size required'} />
          )}
        </div>
        {has_warnings && (
          <div className="bg-wb-red rounded-lg max-w-[160px] md:w-max md:max-w-[200px] text-[10px] md:text-base md:left-[20%] text-white flex items-center p-2 mx-auto my-[0.5rem] md:my-2 absolute">
            <img src={Warning} className="warning-icon  h-[1.25rem]" alt="grid-warning" />
            <p className="p-1 md:p-2">You must place a panel here</p>
          </div>
        )}
      </SectionContainer>
      <SectionContainer customClassNames="relative md:p-0 md:pt-0 md:pb-2 flex-1 flex flex-col justify-center">
        <Configurator max_height={max_rows} max_length={max_columns} elevationIndex={elevationIndex} />
      </SectionContainer>
      <SectionContainer customClassNames="relative flex flex-col items-center justify-center gap-2 lg:justify-between  my-10 md:my-0 md:p-0 md:!pt-0">
        <div className="flex w-full justify-between flex-col md:flex-row px-4 md:px-2 gap-2">
          <div className="max-w-[400px]">
            <Subheading
              variant="sm"
              text="Please Note: An exclusion zone of 400mm is required around the edge of the roof. This design is based on the information provided and is not guaranteed."
            />
          </div>
        </div>
        <div className="flex justify-between w-full items-center flex-col md:flex-row">
          <div className="flex gap-6 mt-[20px] md:mt-[0]">
            <ActionBtn variant="prev" onClick={handleBack} />
            <BinBtn onClick={handleClear} />
            <ActionBtn variant="next" onClick={handleConfirm} disabled={!validSystems.length} />
          </div>
          <div className="w-full pt-0 px-4 md:px-0 md:w-auto mt-[20px] md:mt-0 ">
            <ElevationPower
              variant="full-width"
              current={current_power}
              max={elevationSelected?.max_power || 0}
              onMaxPossibleClick={onMaxPossibleClick}
            />
          </div>
        </div>
      </SectionContainer>
    </div>
  );
};

export default Configuration;
